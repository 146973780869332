import { ElMessage } from 'element-plus'
import { ElLoading } from 'element-plus'

const setTips = (data, type, time = 3000) => {
  let text = data.text;
  ElMessage({
    message: text,
    center: true,
    offset: 0,
    duration: time,
    type
  });
}
const warning = (data, time) => {
  setTips(data, 'warning', time);
}
const error = (data, time) => {
  setTips(data, 'error', time);
}
const info = (data, time) => {
  setTips(data, 'info', time);
}
const success = (data, time) => {
  setTips(data, 'success', time);
}
const loading = (data, time = 2000) => {
  let loading = ElLoading.service({
    lock: true,
    text: 'Loading',
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)',
    ...data
  });

  setTimeout(() => { loading.close(); }, time);
}
const customLoading = (data = {}, time = 2000) => {
  let loading = ElLoading.service({
    lock: true,
    text: 'Loading',
    spinner: 'el-icon-loading',
    background: 'rgba(0, 0, 0, 0.7)',
    ...data
  });
  return loading
}
export default {
  warning,
  error,
  info,
  success,
  loading,
  customLoading
}