import axios from 'axios'
import tool from '@/common/tool'
import config from '@/common/config'
import tips from '@/common/tips'
import store from '@/store'
const getHeaders = () => {
  let adminInfo = store.state.common.adminInfo;
  let otherInfo = store.state.common.otherInfo;
  let projectId = tool.getStorage("projectId", sessionStorage);
  let companyId = tool.getStorage("projectId", sessionStorage);

  projectId = encodeURIComponent(projectId)
  return {
    openid: tool.getCookie('openid') || '',
    "login-phone": adminInfo.phone || '',
    "project-id": projectId || '',
    "login-company-id": adminInfo.company_id || companyId || 2,
    "login-admin-id": adminInfo.id || '',
    "other-openid": otherInfo.other_openid || "",
    "other-company-id": otherInfo.other_company_id || "",
  };
}
// axios.defaults.withCredentials=true
const tipCode = (err) => {
  let code = err.response.status;
  let errCode = config.tips.errCode
  console.log(code)
  try {
    if (code == 500) {
      tips.error({ text: errCode[500] })
    } else {
      tips.error({ text: err.response.data.msg || errCode[code] || errCode.default })
    }
  } catch (e) {
    console.log(err)
    tips.error({ text: errCode.other })
  }
}
const download = (url, name, type) => {
  axios({
    method: 'get',
    headers: {
      'Content-Type': config.axiosType['type'],
      ...getHeaders()
    },
    responseType: 'blob',
    url: url,
  }).then(res => {
    let blob = new Blob([res.data], { type: config.axiosType[type] });
    if ('msSaveOrOpenBlob' in navigator) {
      window.navigator.msSaveOrOpenBlob(blob, `${name}.${type}`);
      return
    }
    let downloadElement = document.createElement('a');
    let href = window.URL.createObjectURL(blob);
    downloadElement.href = href;
    downloadElement.download = `${name}.${type}`;
    document.body.appendChild(downloadElement);
    downloadElement.click();
    document.body.removeChild(downloadElement);
    window.URL.revokeObjectURL(href);
  }).catch(err => {
    console.log(err)
    tips.error({ text: '暂没有数据阔以导出' });
  });
}
const AxiosGet = async (url, headers, timeout) => {
  return AxiosRequest(url, {}, 'get', headers, timeout)
}
const AxiosPost = async (url, data, headers, timeout) => {
  return AxiosRequest(url, data, 'post', headers, timeout)
}
const AxiosDelete = async (url, headers, timeout) => {
  return AxiosRequest(url, {}, 'delete', headers, timeout)
}
const AxiosPatch = async (url, data, headers, timeout) => {
  return AxiosRequest(url, data, 'patch', headers, timeout)
}

const AxiosRequest = async (url, data = {}, method, headers = {}, timeout = 1000 * 60) => {
  return new Promise((resolve, reject) => {
    axios({
      method,
      url,
      data,
      timeout,
      processData: false,  // 不处理数据
      contentType: false,   // 不设置内容类型
      headers: {
        ...getHeaders(),
        ...headers
      }
    }).then((res) => {
      resolve(res.data)
    }).catch((err) => {
      console.log(err.code)
      try {

        if (err.code == "ECONNABORTED") {
          tips.error({ text: "连接超时，请换个网络重试" })
          reject({ errcode: true, data: null, err: err })
          return;
        }
        let data = err.response?.data;
        if (data.detail) console.log(err.response)
        else tipCode(err);
        reject({ errcode: true, data: null, err: err })
      } catch (e) {
        console.log(JSON.parse(JSON.stringify(err)))
        reject({ errcode: true, data: null, err: err })
      }
    })
  })

}

export default {
  get: AxiosGet,
  post: AxiosPost,
  delete: AxiosDelete,
  patch: AxiosPatch,
  download,
  getHeaders
}
