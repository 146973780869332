// api模块
import config from "@/common/config"
import tool from "@/common/tool"
const urls = {

    openid: "https://server.medflying.com/api/common/wx/openid",//openid
    jssign: 'https://server.medflying.com/api/common/wx/jssign',//获取js SDK
    oauth: '/ui/api/openid_new',//验证
    user: {
        code: "/ui/admin/sms",
        login: "/ui/admin/login",
        signUp:"/#/signUp",
    },
    uploads: {
        uploadFile: "/ui/api/upload_file",
        uploadBase64: "/ui/api/uploadbase64",
        video: "/ui/api/upload_file_voice"
    },
    admin: {
        doctorList: "/ui/admin/doctor_list",
        meetingCount: "/ui/admin/detail",
        doctorInfo: "/ui/admin/doctor_info",
        doctorMeetingList: "/ui/admin/doctor_meet_list",
        meet: "/ui/admin/meet",
        newMeet: "/ui/admin/new_meet",
        meetDetail: "/ui/doctor/meet_detail",
        meetSign: "/ui/admin/meet_sign",
        meetError: "/ui/admin/meet_error",
        getCourseInfo: "/ui/api/class_info",
        auditStatus: "/ui/admin/audit_status",
        project: "/ui/api/project_detail",
        admin_share: "/ui/api/admin_send_ppt",
        preview: '/ui/api/doctor_page',
        myProject: "/ui/admin/admin_project",
        doctorInfoResume: "/ui/admin/doctor_info_resume", //简历信息
        iorDetail: "/ui/admin/io_detail" //百研小安用户信息
    },
    role: {
        doctor_period: "/lcc_api/kclass_roles/doctor_period"
    },
    doctor: {
        meetList: "/ui/doctor/meet_list",
        historyMeetList: "/ui/doctor/history_meet_list",
        doctorInfo: "/ui/doctor/info",
        register: "/ui/doctor/register",
        hospitals: "/ui/api/hospital",
        departments: "/ui/api/department",
        meetSign: "/ui/api/sign_meet",
        agreementField: "/ui/api/agreement_field",
        hasPage: "/ui/api/has_page",
        setEssence: "/lcc_api/view_app/set_essence",
        image: "/ui/admin/doctor_profile"
    },
    meet: {
        sign: "/ui/api/sign_live",
        fastReply: "/ui/api/custom_question",
        reset: "/ui/api/meet_reset",
        customQuestion: "/ui/api/courseware_content",
        pageContent: '/ui/api/custom_content',
        upload: "/ui/api/upload_many_files",
        uploadWeixin: '/ui/api/upload_wx_image',
        project: "/lcc_api/kclass_meet/meet_project"
    },
    api: {
        project: "/ui/api/project_list",
        courseware: "/ui/api/class_list",
        meetDetail: "/ui/api/meet_detail",
        sendEmail: "/ui/api/class_send",
        pptxDownload: "/ui/api/send_ppt" //幻灯提词下载
    },
    article: {
        articleList: '/lcc_api/kclass_meet/get_article_list',//列表
        articleDetail: '/lcc_api/kclass_meet/article',//详情
        articleHelp: '/lcc_api/kclass_meet/article_help',//原文求助
        chooseArticle: '/lcc_api/kclass_meet/choose_article'//选择该文章post 是否选择get
    },
    search: {
        doctorInfoEndNumber: '/ui/admin/doctor_info_endNumber'
    },
    vote: {
        voteList: "/ui/api/vote_list",//投票列表
        myVote: "/ui/api/vote",//我的投票  
        vote: "/ui/api/vote", //投票上传
        periodList: "/ui/api/period_list",//期数列表
        voteDetail: "/ui/api/vote_detail",//投票详情
        groupVote: '/ui/api/group_vote',//组详情
    },
}

let host = tool.isLocal() ? config.localhost : config.host;
for (let key in urls) {
    if (typeof urls[key] == "object") {
        for (let i in urls[key]) {
            urls[key][i] = `${host}${urls[key][i]}`;
        }
        continue
    }
    
    urls[key] =  urls[key].includes('http') ? urls[key] :  `${host}${urls[key]}`;
}
export default urls;
