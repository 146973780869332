import { createApp, Vue } from 'vue'
import App from './App.vue'
import store from './store'
import router from './router'
import '@/assets/css/reset.css'
import { LocaleInjectionKey, localeProviderMaker } from 'element-plus';
// import 'element-plus/lib/theme-chalk/index.css';
// import { VueCropper }  from "vue-cropper";
import zhCn from 'element-plus/es/locale/lang/zh-cn'
import '@/assets/css/theme/index.scss'
import '@/assets/css/index.css'
import tips from '@/common/tips'
import axios from '@/common/axios'
import tools from '@/common/tool'
import urls from '@/common/urls'
import config from '@/common/config'
import directive from "@/common/directive"
import vConsole from "@/common/vconsole"
var Emitter = require('tiny-emitter');
var emitter = new Emitter();
let VueItem = createApp(App)
VueItem.use(router)
VueItem.use(store)
if (tools.isLocal()) {
  // VueItem.use(vConsole)
}

const opts = { locale: zhCn };
const localeProvides = localeProviderMaker(opts.locale);
directive(VueItem)
VueItem.provide(LocaleInjectionKey, localeProvides);
VueItem.config.globalProperties.$tips = tips
VueItem.config.globalProperties.$axios = axios
VueItem.config.globalProperties.$config = config
VueItem.config.globalProperties.$urls = urls
VueItem.config.globalProperties.$tools = tools
VueItem.config.globalProperties.$emitter = emitter
VueItem.mount('#app')
