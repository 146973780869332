import { createRouter, createWebHashHistory } from "vue-router"
import tool from "@/common/tool"
import config from "@/common/config"
import weixin from "@/common/weixin"
import axios from "@/common/axios"
import urls from "@/common/urls"
import store from "@/store"
const routeType = {
  meeting: "meeting",
  common: "common",
  admin: "admin",
  doctor: "doctor",
  ignore: "ignore"
}
const adminRoutes = [

  {
    path: "/resumeInfo",
    categary: routeType.admin,
    name: "resumeInfo",
    component: () => import(/* webpackChunkName: "resumeInfo", webpackPrefetch: true */ "@/components/page/admin/resumeInfo.vue")
  },
  {
    path: "/doctorPreview",
    categary: routeType.admin,
    name: "doctorPreview",
    component: () => import(/* webpackChunkName: "doctorPreview", webpackPrefetch: true */ "@/components/page/admin/doctorPreview.vue")
  },
  {
    path: "/",
    categary: routeType.admin,
    name: "doctorList",
    component: () => import(/* webpackChunkName: "doctorList", webpackPrefetch: true */ "@/components/page/admin/doctorList.vue")
  },
  {
    path: "/doctorList",
    categary: routeType.admin,
    name: "doctorList",
    component: () => import(/* webpackChunkName: "doctorList", webpackPrefetch: true */ "@/components/page/admin/doctorList.vue")
  },
  {
    path: "/doctorMeets",
    categary: routeType.admin,
    name: "doctorMeets",
    component: () => import(/* webpackChunkName: "doctorMeets", webpackPrefetch: true */ "@/components/page/admin/doctorMeets.vue")
  },
  {
    path: "/createMeeting",
    categary: routeType.admin,
    name: "createMeeting",
    component: () => import(/* webpackChunkName: "createMeeting", webpackPrefetch: true */ "@/components/page/admin/meetingInfo.vue")
  },
  {
    path: "/coursePreview",
    categary: routeType.admin,
    name: "coursePreview",
    component: () => import(/* webpackChunkName: "coursePreview", webpackPrefetch: true */ "@/components/page/admin/coursePreview.vue")
  },



]
const commonRoutes = [
  {
    path: "/login",
    categary: routeType.common,
    name: "login",
    component: () => import(/* webpackChunkName: "login", webpackPrefetch: true */ "@/components/page/user/login.vue")
  },
  {
    path: "/information",
    categary: routeType.ignore,
    name: "information",
    component: () => import(/* webpackChunkName: "information", webpackPrefetch: true */ "@/components/page/user/information.vue")
  }, 
  {
    path: "/signUp",
    categary: routeType.common,
    name: "signUp",
    component: () => import(/* webpackChunkName: "signUp", webpackPrefetch: true */ "@/components/page/user/signUp.vue")
  },
  {
    path: "/searchInlet",
    categary: routeType.common,
    name: "searchInlet",
    component: () => import(/* webpackChunkName: "literatureLimitDetail", webpackPrefetch: true */ "@/components/page/search/searchInlet.vue")
  },
  {
    path: "/searchResult",
    categary: routeType.common,
    name: "searchResult",
    component: () => import(/* webpackChunkName: "literatureLimitDetail", webpackPrefetch: true */ "@/components/page/search/searchResult.vue")
  },
  {
    path: "/agreement/:id/:doctorId",
    categary: routeType.common,
    name: "agreement",
    component: () => import(/* webpackChunkName: "agreement", webpackPrefetch: true */ "@/components/page/agreement/agreement.vue")
  },
  {
    path: "/doctorInfo",
    categary: routeType.admin,
    name: "doctorInfo",
    component: () => import(/* webpackChunkName: "doctorInfo", webpackPrefetch: true */ "@/components/page/admin/doctorInfo.vue")
  },
]
const doctorRoutes = [
  {
    path: "/myMeeting",
    categary: routeType.doctor,
    name: "myMeeting",
    component: () => import(/* webpackChunkName: "myMeeting", webpackPrefetch: true */ "@/components/page/doctor/myMeeting.vue")
  },
  {
    path: "/historyMeeting",
    categary: routeType.doctor,
    name: "historyMeeting",
    component: () => import(/* webpackChunkName: "historyMeeting", webpackPrefetch: true */ "@/components/page/doctor/historyMeeting.vue")
  },
  {
    path: "/meetDetail",
    categary: routeType.doctor,
    name: "meetDetail",
    component: () => import(/* webpackChunkName: "meetDetail", webpackPrefetch: true */ "@/components/page/doctor/meetDetail.vue")
  },

  {
    path: "/meetingInfo",
    categary: routeType.admin,
    name: "meetingInfo",
    component: () => import(/* webpackChunkName: "meetingInfo", webpackPrefetch: true */ "@/components/page/admin/meetingInfo.vue")
  },
]
const meetingRoutes = [
  {
    path: "/meeting/:id",
    categary: routeType.meeting,
    name: "meeting",
    meta: {
      keepAlive: true
    },
    component: () => import(/* webpackChunkName: "meeting", webpackPrefetch: true */ "@/components/page/meeting/meeting.vue")
  }
]
const ignoreRoutes = [

  {
    path: "/LiteratureSeleList",
    categary: routeType.ignore,
    name: "LiteratureSeleList",
    component: () => import(/* webpackChunkName: "LiteratureSeleList", webpackPrefetch: true */ "@/components/page/doctor/LiteratureSeleList.vue")
  },
  {
    path: "/literatureLimit",
    categary: routeType.ignore,
    name: "literatureLimit",
    component: () => import(/* webpackChunkName: "literatureLimit", webpackPrefetch: true */ "@/components/page/doctor/literatureLimit.vue")
  },
  {
    path: "/literatureLimitDetail",
    categary: routeType.ignore,
    name: "literatureLimitDetail",
    component: () => import(/* webpackChunkName: "literatureLimitDetail", webpackPrefetch: true */ "@/components/page/doctor/literatureLimitDetail.vue")
  },
  {
    path: "/page404",
    categary: routeType.ignore,
    name: "page404",
    component: () => import(/* webpackChunkName: "page404", webpackPrefetch: true */ "@/components/common/page404.vue")
  },
  {
    path: "/jump",
    categary: routeType.ignore,
    name: "jump",
    component: () => import(/* webpackChunkName: "jump", webpackPrefetch: true */ "@/components/main/jump.vue")
  },

  // {
  //   path: "/lhtest",
  //   categary: routeType.common,
  //   name: "login",
  //   component: () => import(/* webpackChunkName: "login1", webpackPrefetch: true */ "@/components/common/lhtest.vue")
  // },
  {
    path: "/sign",
    categary: routeType.meeting,
    name: "sign",
    component: () => import(/* webpackChunkName: "sign", webpackPrefetch: true */ "@/components/page/doctor/sign.vue")
  },
]
const voteRoutes = [{
  path: "/challenge",
  categary: routeType.vote,
  name: "challenge",
  component: () => import(/* webpackChunkName: "challenge", webpackPrefetch: true */ "@/components/page/admin/challenge.vue")
},
{
  path: '/vote',
  categary: routeType.vote,
  name: 'vote',
  component: () => import(/* webpackChunkName: "vote", webpackPrefetch: true */ "@/components/page/vote/vote.vue")
},
{
  path: '/voteTest',
  categary: routeType.vote,
  name: 'voteTest',
  component: () => import(/* webpackChunkName: "voteTest", webpackPrefetch: true */ "@/components/page/vote/voteTest.vue")
},
{
  path: '/voteGroupList', categary: routeType.vote,
  name: 'voteGroupList',
  component: () => import(/* webpackChunkName: "voteGroupList", webpackPrefetch: true */ "@/components/page/vote/voteGroupList.vue")
},
{
  path: '/voteDetail', categary: routeType.vote,
  name: 'voteDetail',
  component: () => import(/* webpackChunkName: "voteDetail", webpackPrefetch: true */ "@/components/page/vote/voteDetail.vue")
},]
const routes = [].concat(commonRoutes, adminRoutes, doctorRoutes, meetingRoutes, ignoreRoutes, voteRoutes);
// const routes =  []
const router = createRouter({
  history: createWebHashHistory(),
  routes
});
const ignorePages = ignoreRoutes.map(item => item.name) // 不需要验证的页面
const commonPages = commonRoutes.map(item => item.name)  // 通用页面,只需要openid，其他不验证
const doctorPages = doctorRoutes.map(item => item.name)  // 医生页面
const adminPages = adminRoutes.map(item => item.name) // 管理员页面
const meetingPages = meetingRoutes.map(item => item.name) // 会议界面
const votePages = voteRoutes.map(item => item.name) // 投票界面

//投票页面


router.beforeEach((to, from, next) => {
  // 初始化微信对象
  tool.isLocal() ? development(to, from, next) : produce(to, from, next);
})
async function development(to, from, next) {
  weixin.init()
  // tool.setCookie("openid", "oa7P25876ZiyU9h3fhioeHvGGRIk")//郑雯
  tool.setCookie("openid", "oVufrsx-Jqs5h4URqwqsN542DaMQ") //邓红梅
  // tool.setCookie("openid","oa7P25-TqhaOPC98NIBlR4qJeJIc") //李先东 
  // tool.setCookie("openid", "oVufrs8QBmtmhZxnj2JoC5AcHgcs")
  // tool.setCookie("openid","oEX11t-u6sl1nJ3d3_lqyHk58RO8") //开发者工具
  // tool.setCookie("openid","oa7P254OzGKcJp_J9OtaZUxldKSY") //李先东

  getProjectName(to, from, next)
  if (ignorePages.includes(to.name)) { next(); return; }
  inSystem(to, from, next)
}
async function produce(to, from, next) {
  // weixin.init(() => {
    getProjectName(to, from, next)
    if (ignorePages.includes(to.name)) { next(); return; }

    let openid = tool.getCookie("openid") || to.query.openid || '';
    if (!openid) {
      next({ name: "jump", path: "/jump", query: { path: to.path, ...to.query } })
      return;
    }
    if (to.query?.openid) {
      tool.setCookie("openid", to.query.openid)
    }
    inSystem(to, from, next)
  // })

}
const getProjectName = (to, from, next) => {
  if (to.query.projectId && from.path !== "/myMeeting") {
    let projectId = process.env.VUE_APP_projectId || to.query.projectId
    tool.setStorage("projectId", projectId, sessionStorage)
  }
  let projectId = to.query.projectId || tool.getStorage("projectId", sessionStorage)

  if (projectId && !store.state.common.projectInfo.id) {
    loadProjectInfo()
  }


}
async function inSystem(to, from, next) {

  // next();
  // return;
  let name = to.name;
  // 可跳过 路由
  if (commonPages.includes(name)) { // 通用页面
    next();
    return;
  } else if (doctorPages.includes(name)) { // 医生页面
    if (to.query.other_company_id) {
      store.dispatch("setItem", { otherInfo: to.query })
    }
    isRegister(to, from, next)
  } else if (adminPages.includes(name)) { // 管理员页面
    isLogin(to, from, next)
  } else if (meetingPages.includes(name)) { // 会议界面
    isSign(to, from, next)
  } else if (votePages.includes(name)) {
    getUserInfo(to, from, next, async (data) => {
      store.dispatch("setItem", { infoData: data })
      next()
    })
  }
  else { // 错误的路由
    next("/page404")
  }

}
async function isLogin(to, from, next) {
  let adminInfo = store.state.common.adminInfo;
  console.log('adminInfo :>> ', adminInfo);
  let adminLoginData = tool.getLocalCookie("adminLoginData") || {}
  if (!adminLoginData.phone) {
    next("/login")
    return;
  }
  if (!adminInfo.phone) {
    axios.post(urls.user.login, adminLoginData).then(res => {
      store.dispatch("setItem", { adminInfo: res.data })
      tool.setLocalCookie('adminLoginData', adminLoginData)
      let projectId = tool.getStorage("projectId", sessionStorage);

      if (!projectId) {
        next("/login")
      } else {
        next()
      }
    }).catch(err => {
      next("/login")
    })
    return;
  }
  tool.setLocalCookie('adminLoginData', adminLoginData)
  next();
}

async function isRegister(to, from, next) {
  let doctorInfo = store.state.common.doctorInfo;
  if (doctorInfo.openid) { next(); return; }
  let url = `${urls.doctor.doctorInfo}`
  let response = await axios.get(url)
  if (response.data && response.data.openid) {
    store.dispatch("setItem", { doctorInfo: response.data })
    next()
  } else {
    next("/information")
  }
}
async function isSign(to, from, next) {
  console.log('to :>> ', to);
  let signInfo = store.state.common.signInfo;
  if (signInfo.openid) { next(); return; }
  let url = tool.getURL(urls.meet.sign, { ...tool.getSystemInfo(), meeting_number: to.params.id })
  let response = await axios.get(url)
  if (response.data && response.data.openid) {
    store.dispatch("setItem", { signInfo: response.data })
    next()
  } else {
    next({
      path: "/sign",
      query: {
        meetingId: to.params.id,
        ...to.query
      }
    })
  }
}



const loadProjectInfo = () => {
  let url = tool.getURL(
    urls.admin.project,
    {
      project_id: tool.getStorage("projectId", sessionStorage)
    }
  )
  axios.get(url).then(res => {
    store.dispatch("setItem", { projectInfo: res.data })
    tool.setStorage("is_courseware", res.data.is_courseware, sessionStorage);
  }).catch(err => { })
}
//百研小安信息
const getUserInfo = (to, from, next, callback) => {
  // let url = 'http://ior.imedunion.com/users/detail';
  let infoData = store.state.common.infoData || {};
  console.log(infoData)
  if (infoData.name) {
    callback && callback(infoData)
  } else {

    axios.get(urls.admin.iorDetail).then(res => {
      if (res.err_code) {
        next('/')
        return;
      }
      callback && callback(res.data || {})
    }).catch(err => { console.log('err :>> ', err); })

  }
}


export default router;
