// 使用方式 this.$store.dispatch('setItem',{key:value})
// 使用方式 this.$store.commit('setItem',{key:value})
const modules = {
  common: {
    state() {
      return {
        isConnect: true,
        adminInfo: {},
        doctorInfo: {},
        signInfo: {},
        otherInfo: {},
        projectInfo: {},
        infoData: {}//百研小安用户信息
      }
    },
    mutations: {
      setItem(state, data) {
        for (let key in data) {
          state[key] = data[key];
        }
      }
    },
    actions: {
      setItem(context, data) {
        context.commit("setItem", data)
      }
    }
  }
}

export default modules;