import tools from '@/common/tool'
const  InitInputData = (Vue) => {
  Vue.directive('input-data',{
    mounted(el,binding){
      console.log(el,binding)
    }
  })
}
const InitDeirective = (Vue) => {
    InitInputData(Vue)
}
export default InitDeirective